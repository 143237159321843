.landing {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100vh;
    background-image: url(../../assets/images/intro.jpg);
    background-position: center center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;   
}

.landing h1, .landing h4 {
    color: #fff;
    text-transform: uppercase;
}

.landing h1 {
    font-size: calc(30px + 2vmin);
}

.specials {
    color: white;
}

.btn.btn-specials {
    border-radius: 20px;
    background-color: transparent;
    border-color: white;
    color: white;
    padding-left: 20px;
    padding-right: 20px;
}

.btn.btn-specials:hover {
    background-color: #5755d9;
}